import React from 'react'
import { HeroData } from '../components/Hero/HeroData'
import Hero from '../components/Hero/HeroInfo'


const LandingPage = () => {
  return (
    <>
        <Hero slides={HeroData}/>
    </>
  )
}

export default LandingPage