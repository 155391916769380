import React from "react";
import GlobalStyle from "./globalStyles"
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LandingPage from "./pages/LandingPage"
import MembersLoginPage from "./pages/MembersLoginPage"

function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/memberslogin" component={MembersLoginPage} />
      </Switch>
    </Router>
  );
}

export default App;
