import React from 'react';
import {
	Nav,
	NavbarContainer,
	NavLogo,
	NavIcon,
} from './NavbarStyle';
import { animateScroll as scroll } from 'react-scroll';
import { NavButton } from '../../globalStyles';
import { Link } from 'react-router-dom';


const Navbar = ({ hide }) => {

	return (
		<Nav hide={hide}>
			<NavbarContainer>
				<NavLogo to="/" onClick={scroll.scrollToTop}>
					<NavIcon src="./assets/logo__edit.png" alt="" />
				</NavLogo>
			</NavbarContainer>
			<Link to='memberslogin'>
				<NavButton>Login</NavButton>
				</Link>
		</Nav>
	);
};

export default Navbar;
